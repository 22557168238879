import React, { useEffect, useRef } from 'react';
import { useCodeMirror } from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { oneDark } from '@codemirror/theme-one-dark';
import { EditorView, WidgetType, ViewUpdate, Decoration, DecorationSet } from '@codemirror/view';
import { EditorState, StateField } from '@codemirror/state';

import { logger } from './Logger';

import './CodeEditor.css';


/*
Themes available for codemirror

Default Theme: The standard theme that comes out of the box with CodeMirror.
Light Theme: A lighter theme, suitable for bright interfaces.
Dark Theme: A darker theme, which provides a high-contrast environment ideal for coding in low-light conditions.
One Dark: Inspired by the One Dark theme from the Atom editor, offering a sleek, modern dark color scheme.
Material Theme: Mimics the Material Design aesthetic, with several variations including light, dark, and others.

import { dark } from '@codemirror/theme-dark';
import { oneDark } from '@codemirror/theme-one-dark';
import { material } from '@codemirror/theme-material';
*/

const plainTheme = EditorView.theme({
  '&': {
    color: 'white !important',
  },
});

const studentTheme = EditorView.theme({
  '&': {
    color: 'lightblue !important',
  },
});

const teacherTheme = EditorView.theme({
  '&': {
    color: 'lightpink !important',
  },
});

const codeTheme = oneDark;

class OtherUserCaretWidget extends WidgetType {
  private hideTimeout: NodeJS.Timeout | null = null;

  constructor(private color: string, private name: string, private isTyping: boolean) {
    super();
  }

  toDOM() {
    const wrapper = document.createElement('span');
    wrapper.className = 'cm-otherUserCaretWrapper';
    
    const caretEl = document.createElement('span');
    caretEl.className = 'cm-otherUserCaret';
    caretEl.style.borderLeftColor = this.color;
    
    const caretTop = document.createElement('span');
    caretTop.className = 'cm-otherUserCaretTop';
    caretTop.style.backgroundColor = this.color;
    
    const nameTag = document.createElement('div');
    nameTag.className = 'cm-otherUserName';
    nameTag.textContent = this.name;
    nameTag.style.backgroundColor = this.color;
    nameTag.dataset.user = this.name;
    
    caretEl.appendChild(caretTop);
    wrapper.appendChild(caretEl);
    wrapper.appendChild(nameTag);
    
    const showNameTag = () => {
      nameTag.style.opacity = '1';
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
      }
    };

    const hideNameTag = (delay: number) => {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
      }
      this.hideTimeout = setTimeout(() => {
        nameTag.style.opacity = '0';
      }, delay);
    };

    wrapper.addEventListener('mouseover', () => {
      showNameTag();
    });

    wrapper.addEventListener('mouseout', () => {
      hideNameTag(1000);  // 1 seconds for mouseover
    });
    
    if (this.isTyping) {
      showNameTag();
      hideNameTag(3000);  // 3 seconds for typing
    }
    
    return wrapper;
  }
}

interface CodeMirrorEditorProps {
  value: string;
  fileExtension: string;
  onUpdate?: (value: string, caretPosition: { line: number; col: number }, highlightedRange: { anchor: { line: number; col: number }; head: { line: number; col: number } }) => void;
  readOnly?: boolean;
  userInfo: {
    [userName: string]: {
      userType: 'student' | 'teacher';
      caretPosition: { line: number; col: number };
      highlightedRange: { anchor: { line: number; col: number }; head: { line: number; col: number } };
      activeFile: string | null;
    };
  };
  typingNames: string[];
  currentUser: string;
  fileOwner: string;
  isStudentFile: boolean;
  fileName: string;
  highlightOption: string;
  isOutputPane: boolean
}

const CodeMirrorEditor: React.FC<CodeMirrorEditorProps> = ({ 
  value, fileExtension, onUpdate, readOnly = false, userInfo, 
  typingNames, currentUser, fileOwner, isStudentFile, fileName, highlightOption, isOutputPane
}) => {
  const editorContainer = useRef<HTMLDivElement | null>(null);
  logger.log('CME created, isStudentFile: ', isStudentFile);

  const getTheme = () => {
    switch (highlightOption) {
      case 'plain':
        return plainTheme;
      case 'student':
        return studentTheme;
      case 'teacher':
        return teacherTheme;
      case 'code':
      default:
        return codeTheme;
    }
  };

  const getPositionInfo = (state: EditorState, pos: number) => {
    const line = state.doc.lineAt(pos);
    return {
      line: line.number,
      col: pos - line.from
    };
  };

  
  // Define a state field for managing decorations
  const otherUserDecorations = StateField.define<DecorationSet>({
    create() {
      return Decoration.none;
    },
    update(decorations, transaction) {
      const doc = transaction.state.doc;
      const activeFileName = fileOwner + '-' + fileName;
      const otherUsers = Object.entries(userInfo).filter(([userName, info]) => 
        userName !== currentUser && info.activeFile === activeFileName
      );
      
      const newDecorations = otherUsers.flatMap(([userName, info]) => {
        const { caretPosition, highlightedRange, userType } = info;
        const color = userType === 'teacher' ? '#FF69B4' : '#4169E1'; // Pink for teachers, Blue for students
        const decorations = [];

        // Caret decoration
        if (caretPosition && caretPosition.line > 0 && caretPosition.line <= doc.lines) {
          const caretLine = doc.line(caretPosition.line);
          const caretPos = Math.min(caretLine.from + caretPosition.col, caretLine.to);

          //const isUserTyping = isTyping && typingName === userName;
          const isUserTyping = typingNames.includes(userName);

          decorations.push(Decoration.widget({
            widget: new OtherUserCaretWidget(color, userName, isUserTyping),
            side: 1,
            block: false
          }).range(caretPos));
        }

        // Highlight decoration
        if (highlightedRange && 
            highlightedRange.anchor.line > 0 && highlightedRange.anchor.line <= doc.lines &&
            highlightedRange.head.line > 0 && highlightedRange.head.line <= doc.lines) {
          const anchorLine = doc.line(highlightedRange.anchor.line);
          const headLine = doc.line(highlightedRange.head.line);
          const from = Math.min(anchorLine.from + highlightedRange.anchor.col, anchorLine.to);
          const to = Math.min(headLine.from + highlightedRange.head.col, headLine.to);
          
          if (from !== to) {
            decorations.push(Decoration.mark({
              class: 'cm-otherUserHighlight',
              attributes: { style: `background-color: ${color}33` }
            }).range(Math.min(from, to), Math.max(from, to)));
          }
        }
  
        return decorations;
      });
  
      return Decoration.set(newDecorations, true);
    },
    provide: f => EditorView.decorations.from(f)
  });
  

  const handleUpdate = EditorView.updateListener.of((update: ViewUpdate) => {
    if (update.selectionSet) {
      const state = update.state;
      const selection = state.selection.main;
      
      const caretPosition = getPositionInfo(state, selection.head);
      const anchorPosition = getPositionInfo(state, selection.anchor);
      const headPosition = getPositionInfo(state, selection.head);

      onUpdate?.(state.doc.toString(), caretPosition, {
        anchor: anchorPosition,
        head: headPosition
      });
    }
  });

  const backgroundTheme = EditorView.theme({
    "&": {
      backgroundColor: isStudentFile ? 
      (isOutputPane ? "var(--purple-shade-2)" : "var(--purple-shade-3)") :
      (isOutputPane ? "var(--background-color)" : "var(--shade-3)"),
    },
    ".cm-scroller": {
      backgroundColor: isStudentFile ? 
      (isOutputPane ? "var(--purple-shade-2)" : "var(--purple-shade-3)") :
      (isOutputPane ? "var(--background-color)" : "var(--shade-3)"),
    },
  });

  const gutterTheme = EditorView.theme({
    ".cm-gutters": {
      backgroundColor: isStudentFile ? 
      (isOutputPane ? "var(--purple-shade-2) !important" : "var(--purple-shade-3) !important") :
      (isOutputPane ? "var(--background-color) !important" : "var(--shade-3) !important"),
      borderRight: "1px solid var(--shade-4) !important", // Optional: adds a subtle border
      color: "var(--shade-6) !important", // This sets the color of the line numbers
    },
    ".cm-activeLineGutter": {
      backgroundColor: isStudentFile ? 
      (isOutputPane ? "var(--purple-shade-2) !important" : "var(--purple-shade-3) !important") :
      (isOutputPane ? "var(--background-color) !important" : "var(--shade-3) !important"),
      color: "var(--shade-6) !important", // This sets the color of the line numbers
    }
  });

  
  const extensions = [
    ...(highlightOption === 'code' ? 
      [
        fileExtension === 'txt' ? [] : [python()],
        getTheme(),
      ] : 
      [
        fileExtension === 'txt' ? [] : [],
        getTheme(),
      ]),
    handleUpdate,
    otherUserDecorations,
    backgroundTheme,
    gutterTheme,
    EditorView.theme({
      "&": {
        height: "100%",
        overflow: "auto",
        //backgroundColor: "var(--shade-2)",
      },
      ".cm-scroller": {
        overflow: "auto",
        //backgroundColor: "var(--shade-2)",
      },
      '.cm-otherUserHighlight': {
        backgroundColor: 'rgba(48, 188, 237, 0.2)',
      },
      '.cm-otherUserCaretWrapper': {
        display: 'inline-block',
        position: 'relative',
        width: 0,
        height: '1.2em',
      },
      '.cm-otherUserCaret': {
        position: 'absolute',
        top: '3px',
        bottom: 0,
        left: -1,
        width: 0,
        borderLeft: '2px solid',
        height: '100%',
      },
      '.cm-otherUserCaretTop': {
        position: 'absolute',
        top: '-3px',
        left: '-3px',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
      },
      '.cm-otherUserName': {
        position: 'absolute',
        left: '4px',
        top: '-0.2em',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: 'normal',
        userSelect: 'none',
        color: 'white',
        padding: '1px 3px',
        borderRadius: '3px',
        whiteSpace: 'nowrap',
        opacity: 0,
        transition: 'opacity 0.5s ease-out',
        pointerEvents: 'none',
        zIndex: 1000
      },
      '.cm-otherUserName[style*="opacity: 1"]': {
        transition: 'none'  // This removes the transition when opacity is increasing
      },
      '.cm-otherUserCaretWrapper:hover .cm-otherUserName': {
        opacity: 1,
      },
      '.cm-otherUserName:hover .cm-otherUserName': {
        opacity: 1,
      },
    }),
  ];

  // Directly use the hook here, outside of any callbacks or conditional blocks
  const { setContainer } = useCodeMirror({
    container: editorContainer.current,
    value,
    extensions,
    basicSetup: {
      lineNumbers: fileExtension !== 'txt',
    },
    theme: oneDark,
    onChange: readOnly ? undefined : (value: any, viewUpdate: any) => {},
    readOnly,
    
  });

  // Ensure the container is properly linked to the CodeMirror instance
  useEffect(() => {
    setContainer(editorContainer.current);
    return () => setContainer(null); // Clean up
  }, [setContainer]);

  return (
    <div ref={editorContainer} className="code-mirror-container custom-scrollbar" style={{ height: '100%', fontSize: '0.9rem'}}></div>
  );
};



export default CodeMirrorEditor;
