import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { FaPlus } from 'react-icons/fa';

import EditorHeader from './EditorHeader';
import { logger } from './Logger';
import { useColorScheme } from './ColorSchemeContext';
import { Course } from './types';

import './CourseSelectionScreen.css';
import './StudentScreen.css';
import FileRenameInput from './code-editor/FileRenameInput';
import CourseDropdown from './code-editor/CourseDropdown';
import { create } from 'lodash';



let backendURL = process.env.REACT_APP_BACKEND_URL
if (backendURL == null){
    backendURL = "http://localhost:8000"
}

interface CourseSelectionScreenProps {
  isTeacher: boolean;
}

const CourseSelectionScreen: React.FC<CourseSelectionScreenProps> = ({ isTeacher }) => {
  const { changeColorScheme } = useColorScheme();
  const [name, setName] = useState('');
  const [courses, setCourses] = useState<Course[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [renameCourse, setRenameCourse] = useState<string | null>(null);
  const navigate = useNavigate();

  const promptForName = useCallback(() => {
    const nameString = isTeacher ? 'teacherName' :'name';
    let inputName = '';
    while (inputName.trim() === '' || inputName.includes('-')) {
      inputName = prompt('Please enter your name (cannot be empty or contain "-"):') || '';
    }
    localStorage.setItem(nameString, inputName);
    setName(inputName);
    fetchCourses();
  }, [isTeacher, name]);

  useEffect(() => {
    setTimeout(() => {
      const nameString = isTeacher ? 'teacherName' :'name';
      const storedName = localStorage.getItem(nameString);
      if (storedName && storedName.trim() !== '' && !storedName.includes('-')) {
        setName(storedName);
        fetchCourses();
      } else {
        promptForName();
      }
      setLoading(false);
    }, 0);
  }, [isTeacher, promptForName]);

  

  const fetchCourses = async () => {
    try {
      const coursesURL = backendURL + '/courses';
      const response = await fetch(coursesURL);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setErrorMessage('Failed to fetch courses. Please ask support for help.');
    }
  };

  const createCourse = async () => {
    try {
      logger.log('createCourse called');
      const coursesURL = backendURL + '/create-course';
      const response = await fetch(coursesURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error('Error creating course:', error);
      setErrorMessage('Failed to create course. Please ask support for help.');
    }
  };

  const updateCourseName = async (courseId: number, newName: string) => {
    try {
      logger.log('updateCourseName called with courseId:', courseId, 'and newName:', newName);
      const coursesURL = backendURL + '/update-course-name/' + courseId;
      const response = await fetch(coursesURL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newName }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRenameCourse(null);
      setCourses(data);
    } catch (error) {
      console.error('Error updating course name:', error);
      setErrorMessage('Failed to update course name. Please ask support for help.');
    }
  };

  const updateCourseDescription = async (courseId: number, newDescription: string) => {
    try {
      logger.log('updateCourseDescription called for courseId: ', courseId, ', newDescription: ', newDescription);
      const coursesURL = backendURL + '/update-course-description/' + courseId;
      const response = await fetch(coursesURL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ description: newDescription }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error('Error updating course description:', error);
      setErrorMessage('Failed to update course description. Please ask support for help.');
    }
  };

  const updateCourseState = async (courseId: number, newState: string) => {
    try {
      logger.log('updateCourseDescription called for courseId: ', courseId, ', newState: ', newState);
      const coursesURL = backendURL + '/update-course-state/' + courseId;
      const response = await fetch(coursesURL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ state: newState }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error('Error  updating course state:', error);
      setErrorMessage('Failed to update course state. Please ask support for help.');
    }
  };


  const handleCourseSelect = (courseName: string, closed: boolean) => {
    if (closed && !isTeacher) { return; }
    const underscoreCourseName = courseName.replace(/\s+/g, '_').replace(/:/g, '');
    const urlCourseName = encodeURIComponent(underscoreCourseName.toLowerCase());
    const firstPartUrl = isTeacher ? 'teacher406816' : 'student';
    navigate(`/${firstPartUrl}/${urlCourseName}`);
  };

  const handleMenuItemClick = useCallback((menuItem: string) => {
    logger.log('CSS: handleMenuItemClick: ', menuItem);
    if (menuItem === 'Log out') {
      localStorage.removeItem('name');
      localStorage.removeItem('colorScheme');
      changeColorScheme('dark');  // change to the default color scheme
      setName(''); // this prompts the user to enter their name
      navigate(`/`);
    
    } else if (menuItem === 'Home') {
      logger.log('CSS: Calling home function');
      navigate(`/`);

    } else if (menuItem === 'Courses') {
      logger.log('CSS: Calling courses function, here already do nothing');
    }
  }, []);

  if (loading) {
    return <div className="loading-screen">Loading...</div>; // Or any other placeholder
  }

  return (
    <div className="course-selection-container">
      <EditorHeader
        infoMessage={errorMessage}
        connectedMessage={''}
        name={name}
        isTeacher={isTeacher}
        canClearRedis={false}
        handleMenuItemClick={handleMenuItemClick}
        isStealthMode={false}
        showStealthToggle={false}
        setStealthMode={() => {}}
      />

      <div className="welcome-message-container">
        <h1 className="welcome-message">Welcome {name}</h1>
      </div>

      <div className="course-card-container">
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <h2 className="my-courses">My Courses</h2>
            <div className="course-grid">
              {courses
                .filter(course => isTeacher || course.state !== 'hidden')
                .map((course, index) => (
                <div key={index} className={`course-card ${course.state === 'closed' ? 'closed' : ''} ${course.state === 'hidden' ? 'hidden' : ''} ${isTeacher ? 'teacher' : 'student'}`} 
                  onClick={() => handleCourseSelect(course.name, course.state === 'closed')}
                >
                  <div className="course-header">
                    {renameCourse === course.name ? (
                      <FileRenameInput
                        initialFileName={course.name}
                        onRenameComplete={(newName) => updateCourseName(course.id, newName)}
                        onCancel={() => setRenameCourse(null)}
                      />
                    ) : (
                      course.name
                    )}
                  </div>
                  <div className="course-description">{course.description}</div>
                  <div className="course-lesson-count">
                    {course.lessonCount} lessons 
                    {course.state === 'closed' ? '(course closed)' : ''} 
                    {course.state === 'hidden' ? '(course hidden)' : ''} 
                    {course.state === 'template' ? '(course template - read only)' : ''}
                    {isTeacher && course.state !== 'template' && (
                      <CourseDropdown
                        course={course}
                        onUpdateCourseName={() => setRenameCourse(course.name)}
                        onUpdateCourseDescription={(newDescription) => updateCourseDescription(course.id, newDescription)}
                        onUpdateCourseState={(newState) => updateCourseState(course.id, newState)}
                      />
                    )}
                  </div>
                </div>
              ))}
              {isTeacher && (
                <div className="course-card add-course" onClick={createCourse}>
                  <FaPlus className="add-course-icon" />
                  <div className="add-course-text">Add Course</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSelectionScreen;