import { ColorSchemes } from "./types";
import { generateShades } from "./utilities";

const mainPurpleColor = '#6817AE';
const lightPurpleColor = '#E0C6FF'

// const baseSchemes = {
//   light: { background: '#FFFFFF', foreground: '#000000', mainPurple: lightPurpleColor },
//   dark: { background: '#0B0B0B', foreground: '#FFFFFF', mainPurple: mainPurpleColor },
//   sepia: { background: '#F1E7D0', foreground: '#704214', mainPurple: mainPurpleColor },
//   forest: { background: '#2C3E50', foreground: '#ECF0F1', mainPurple: mainPurpleColor },
//   ocean: { background: '#34495E', foreground: '#3498DB', mainPurple: mainPurpleColor },
//   sunset: { background: '#E74C3C', foreground: '#F1C40F', mainPurple: mainPurpleColor },
//   emerald: { background: '#2ecc71', foreground: '#ffffff', mainPurple: mainPurpleColor },
//   amethyst: { background: '#9b59b6', foreground: '#ffffff', mainPurple: mainPurpleColor },
//   sunflower: { background: '#f1c40f', foreground: '#2c3e50', mainPurple: mainPurpleColor },
//   alizarin: { background: '#e74c3c', foreground: '#ecf0f1', mainPurple: mainPurpleColor },
//   turquoise: { background: '#1abc9c', foreground: '#ffffff', mainPurple: mainPurpleColor },
//   "midnight Blue": { background: '#2c3e50', foreground: '#ecf0f1', mainPurple: mainPurpleColor },
//   concrete: { background: '#95a5a6', foreground: '#ffffff', mainPurple: mainPurpleColor },
//   orange: { background: '#f39c12', foreground: '#ffffff', mainPurple: mainPurpleColor },
//   pumpkin: { background: '#d35400', foreground: '#ecf0f1', mainPurple: mainPurpleColor },
//   pomegranate: { background: '#c0392b', foreground: '#ffffff', mainPurple: mainPurpleColor },
//   silver: { background: '#bdc3c7', foreground: '#2c3e50', mainPurple: mainPurpleColor },
//   asbestos: { background: '#7f8c8d', foreground: '#ffffff', mainPurple: mainPurpleColor },
//   "wet Asphalt": { background: '#34495e', foreground: '#ecf0f1', mainPurple: mainPurpleColor },
//   greenSea: { background: '#16a085', foreground: '#ffffff', mainPurple: mainPurpleColor },
//   "belize Hole": { background: '#2980b9', foreground: '#ffffff', mainPurple: mainPurpleColor },
//   wisteria: { background: '#8e44ad', foreground: '#ffffff', mainPurple: mainPurpleColor },
//   "forest Camo": { background: '#4B5320', foreground: '#A9A032', mainPurple: mainPurpleColor },
//   "desert Camo": { background: '#D2B48C', foreground: '#8B4513', mainPurple: mainPurpleColor },
//   "neon Buzz": { background: '#FF1493', foreground: '#00FF00', mainPurple: mainPurpleColor },
//   cyberpunk: { background: '#0D0221', foreground: '#FF00FF', mainPurple: mainPurpleColor },
//   rainforest: { background: '#228B22', foreground: '#FFD700', mainPurple: mainPurpleColor },
//   "ocean Depths": { background: '#000080', foreground: '#40E0D0', mainPurple: mainPurpleColor },
//   "autumn Leaves": { background: '#8B4513', foreground: '#FFA500', mainPurple: mainPurpleColor },
//   "cherry Blossom": { background: '#FFB7C5', foreground: '#800080', mainPurple: mainPurpleColor },
//   nebula: { background: '#4B0082', foreground: '#FF69B4', mainPurple: mainPurpleColor },
//   "solar Flare": { background: '#FF4500', foreground: '#FFD700', mainPurple: mainPurpleColor },
//   "bubblegum Pop": { background: '#FF69B4', foreground: '#1E90FF', mainPurple: mainPurpleColor },
//   "dinosaur World": { background: '#228B22', foreground: '#FF6347', mainPurple: mainPurpleColor },
//   "underwater Adventure": { background: '#00CED1', foreground: '#FF8C00', mainPurple: mainPurpleColor },
//   "unicorn Dreams": { background: '#DA70D6', foreground: '#4169E1', mainPurple: mainPurpleColor },
//   "arctic Tundra": { background: '#E0FFFF', foreground: '#4682B4', mainPurple: mainPurpleColor },
//   volcanic: { background: '#8B0000', foreground: '#FFA07A', mainPurple: mainPurpleColor },
//   meadow: { background: '#90EE90', foreground: '#8B008B', mainPurple: mainPurpleColor },
//   "coral Reef": { background: '#00FFFF', foreground: '#FF1493', mainPurple: mainPurpleColor },
//   "northern Lights": { background: '#191970', foreground: '#7CFC00', mainPurple: mainPurpleColor },
//   "desert Sunset": { background: '#FF8C00', foreground: '#8A2BE2', mainPurple: mainPurpleColor },
// };

const baseSchemes = {
  light: { background: '#FFFFFF', foreground: '#000000', mainPurple: lightPurpleColor },
  dark: { background: '#0B0B0B', foreground: '#FFFFFF', mainPurple: mainPurpleColor },
  sepia: { background: '#F1E7D0', foreground: '#704214', mainPurple: '#8B4B8B' },
  forest: { background: '#2C3E50', foreground: '#ECF0F1', mainPurple: '#8EA9A9' },
  ocean: { background: '#34495E', foreground: '#3498DB', mainPurple: '#1ABC9C' },
  sunset: { background: '#E74C3C', foreground: '#F1C40F', mainPurple: '#8E44AD' },
  emerald: { background: '#2ecc71', foreground: '#ffffff', mainPurple: '#16a085' },
  amethyst: { background: '#9b59b6', foreground: '#ffffff', mainPurple: '#8e44ad' },
  sunflower: { background: '#f1c40f', foreground: '#2c3e50', mainPurple: '#d35400' },
  alizarin: { background: '#e74c3c', foreground: '#ecf0f1', mainPurple: '#c0392b' },
  turquoise: { background: '#1abc9c', foreground: '#ffffff', mainPurple: '#16a085' },
  "midnight Blue": { background: '#2c3e50', foreground: '#ecf0f1', mainPurple: mainPurpleColor },
  concrete: { background: '#95a5a6', foreground: '#ffffff', mainPurple: mainPurpleColor },
  orange: { background: '#f39c12', foreground: '#ffffff', mainPurple: '#d35400' },
  pumpkin: { background: '#d35400', foreground: '#ecf0f1', mainPurple: '#e67e22' },
  pomegranate: { background: '#c0392b', foreground: '#ffffff', mainPurple: '#e74c3c' },
  silver: { background: '#bdc3c7', foreground: '#2c3e50', mainPurple: lightPurpleColor },
  asbestos: { background: '#7f8c8d', foreground: '#ffffff', mainPurple: mainPurpleColor },
  "wet Asphalt": { background: '#34495e', foreground: '#ecf0f1', mainPurple: mainPurpleColor },
  greenSea: { background: '#16a085', foreground: '#ffffff', mainPurple: '#1abc9c' },
  "belize Hole": { background: '#2980b9', foreground: '#ffffff', mainPurple: '#3498db' },
  wisteria: { background: '#8e44ad', foreground: '#ffffff', mainPurple: '#9b59b6' },
  "forest Camo": { background: '#4B5320', foreground: '#A9A032', mainPurple: '#6B8E23' },
  "desert Camo": { background: '#D2B48C', foreground: '#8B4513', mainPurple: '#CD853F' },
  "neon Buzz": { background: '#FF1493', foreground: '#00FF00', mainPurple: '#FF00FF' },
  cyberpunk: { background: '#0D0221', foreground: '#FF00FF', mainPurple: '#00FFFF' },
  rainforest: { background: '#228B22', foreground: '#FFD700', mainPurple: '#32CD32' },
  "ocean Depths": { background: '#000080', foreground: '#40E0D0', mainPurple: '#4169E1' },
  "autumn Leaves": { background: '#8B4513', foreground: '#FFA500', mainPurple: '#D2691E' },
  "cherry Blossom": { background: '#FFB7C5', foreground: '#800080', mainPurple: '#FF69B4' },
  nebula: { background: '#4B0082', foreground: '#FF69B4', mainPurple: '#8A2BE2' },
  "solar Flare": { background: '#FF4500', foreground: '#FFD700', mainPurple: '#FFA500' },
  "bubblegum Pop": { background: '#FF69B4', foreground: '#1E90FF', mainPurple: '#FF1493' },
  "dinosaur World": { background: '#228B22', foreground: '#FF6347', mainPurple: '#32CD32' },
  "underwater Adventure": { background: '#00CED1', foreground: '#FF8C00', mainPurple: '#20B2AA' },
  "unicorn Dreams": { background: '#DA70D6', foreground: '#4169E1', mainPurple: '#9370DB' },
  "arctic Tundra": { background: '#E0FFFF', foreground: '#4682B4', mainPurple: lightPurpleColor },
  volcanic: { background: '#8B0000', foreground: '#FFA07A', mainPurple: '#FF4500' },
  meadow: { background: '#90EE90', foreground: '#8B008B', mainPurple: '#32CD32' },
  "coral Reef": { background: '#00FFFF', foreground: '#FF1493', mainPurple: '#FF69B4' },
  "northern Lights": { background: '#191970', foreground: '#7CFC00', mainPurple: '#4169E1' },
  "desert Sunset": { background: '#FF8C00', foreground: '#8A2BE2', mainPurple: '#FF4500' },
};

export const colorSchemes: ColorSchemes = Object.entries(baseSchemes).reduce((acc, [key, scheme]) => {
  const { shades, purpleShades } = generateShades(scheme.background, scheme.foreground, scheme.mainPurple);
  acc[key] = {
    ...scheme,
    shades,
    purpleShades
  };
  return acc;
}, {} as ColorSchemes);