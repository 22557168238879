import React, { useState } from 'react';

import CodeMirrorEditor from './CodeMirrorEditor';
import './CodeEditor.css';

// Note: If you use this again, split it out into TestEditorBrython to move the brython code away

const TestEditor: React.FC = () => {
  const name = 'Nathan'
  const [output, setOutput] = useState('');
  const [code, setCode] = useState('print("Hello, World!")');

  /*
  // brython loading
  useEffect(() => {
    const loadScripts = async () => {
      // Load Brython
      await loadScript('https://cdn.jsdelivr.net/npm/brython@3/brython.min.js');
      await loadScript('https://cdn.jsdelivr.net/npm/brython@3/brython_stdlib.js');
      
      // Load p5.js
      await loadScript('https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.4.0/p5.js');

      // load js-turtle
      //await loadScript('https://cdn.jsdelivr.net/gh/bjpop/js-turtle@master/turtle.js');
      
      // Initialize Brython
      (window as any).brython({debug: 1, indexedDB: false});
    };
  
    loadScripts();
  
    return () => {
      
    };
  }, []);

  const loadScript = (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
      document.head.appendChild(script);
    });
  };
  */

  const updateFiles = (code: string) => {
    console.log('updateFiles called');
    setCode(code);
  };

  const userInfo =  {
    Nathan: {
      userType: 'student' as const,
      caretPosition: { line: 0, col: 0 },
      highlightedRange: { anchor: { line: 0, col: 0 }, head: { line: 0, col: 0 } },
      activeFile: 'main.py'
    }
  };

  const runCode = () => {
    console.log('RunCode called')
    
    setOutput(''); // Clear previous output

    //const isTurtle = code.includes('import turtle') || code.includes('from turtle import');

    // there is an issue with the main_canvas having multiple event listeners for key presses, tried to fix but it didn't work
    const mainCanvas = (window as any).Sk.main_canvas;
    if (mainCanvas && mainCanvas.parentNode) {
      mainCanvas.parentNode.removeChild(mainCanvas);
      (window as any).Sk.main_canvas = null;
    }
    (window as any).Sk.main_canvas = document.createElement("canvas");
   
    const turtleDiv = document.getElementById('turtle-canvas-pane');
    if (turtleDiv) {
      turtleDiv.innerHTML = ''; // Clear previous turtle graphics
    } 

    // configure Skulpt
    (window as any).Sk.configure({
      output: function(text: string) {
        setOutput(output => output + text);
        const textOutput = document.getElementById('modal-text-output');
        if (textOutput) {
          textOutput.innerText += text; // Update text output live
        }
      },
      read: (x: any) => {
        if ((window as any).Sk.builtinFiles === undefined || (window as any).Sk.builtinFiles.files[x] === undefined) {
          throw new Error("File not found: '" + x + "'");
        }
        return (window as any).Sk.builtinFiles.files[x];
      },
    });

    (window as any).Sk.quitHandler = () => {
      console.log('quitHandler called');
    };

    // Set the output target based on useModal and the type of code
    const outputTarget = "turtle-canvas-pane"; 

    ((window as any).Sk.TurtleGraphics || ((window as any).Sk.TurtleGraphics = {})).target = outputTarget;

    // Run the code using Skulpt
    (window as any).Sk.misceval.asyncToPromise(() => {
      return (window as any).Sk.importMainWithBody("<stdin>", false, code, true);
    })
    .then(() => {
      console.log('CodeEditor for ', name, ': code ran successfully');
    })
    .catch((err: Error) => {
      console.log('CodeEditor for ', name, ': code error');
      setOutput(output => output + '\n' + err.toString());
    });
   
  };

  // brython code

  /*
  const runCode = (mainPane: boolean) => {
    setIsRunning(true);
    setOutput('');
    setIsWaitingForInput(false);
    setActiveRightPaneTab('console');
  
    const [fileType, ...rest] = currentFile.split('-');
    const fileName = rest.pop() ?? '';
    const studentName = rest.join('-');
    let code = mainPane ? 
      (fileType === 'student') ? codeData['studentFiles'][studentName][fileName] : codeData['teacherFiles'][fileName] : 
      codeData['teacherFiles']?.[currentRightPaneCodeFile?? ''] || '';

    // Add indentation to the user's code
    code = code.split('\n').map(line => '        ' + line).join('\n');

    // Replace input( with await input(
    // note there is a risk this might include code inside quotes, but it's unlikely
    code = code.replace(/(\W|^)(input\()/g, '$1await input(');
  
    // Create a new script element with the Python code
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/python';
    scriptElement.innerHTML = `
import sys
from browser import document, window, aio
from browser import html
import turtle

class PyTurtle:
    def __init__(self):
        if not hasattr(window, 'Turtle'):
            raise AttributeError("Turtle is not defined. Make sure js-turtle is loaded.")
        self.js_turtle = window.Turtle()

    def forward(self, distance):
        self.js_turtle.forward(distance)

    def backward(self, distance):
        self.js_turtle.backward(distance)

    def right(self, angle):
        self.js_turtle.right(angle)

    def left(self, angle):
        self.js_turtle.left(angle)

    def penup(self):
        self.js_turtle.penup()

    def pendown(self):
        self.js_turtle.pendown()

    def goto(self, x, y):
        self.js_turtle.goto(x, y)

    def color(self, color):
        self.js_turtle.color(color)

    def width(self, width):
        self.js_turtle.width(width)

    def circle(self, radius):
        self.js_turtle.circle(radius)

    def dot(self, size, color=None):
        if color:
            self.js_turtle.color(color)
        self.js_turtle.dot(size)

    def write(self, text, font=None):
        if font:
            self.js_turtle.font(*font)
        self.js_turtle.write(text)

def create_turtle():
    return PyTurtle()

def reset():
    window.reset()

class P5Wrapper:
    def __init__(self, setup_func, draw_func):
        self.p5 = window.p5.new(self.sketch)
        self.setup_func = setup_func
        self.draw_func = draw_func

    def sketch(self, p):
        def setup():
            self.setup_func(p)

        def draw():
            self.draw_func(p)

        p.setup = setup
        p.draw = draw

def create_sketch(setup_func, draw_func):
    return P5Wrapper(setup_func, draw_func)

window.create_sketch = create_sketch

def update():
    # js-turtle updates automatically, so this is just a placeholder
    pass

def update_old(turt=None):
    if turt is None:
        turt = turtle
    screen = turt.Screen()
    
    print("Starting update function")
    current_frame = getattr(screen, 'frame_index', 0)
    print(f"Current frame index: {current_frame}")
    
    # Create a new animation group for the updates since the last call
    update_group = turt.svg.g()
    
    print(f"Number of turtles: {len(getattr(screen, '_turtles', []))}")
    
    last_drawn_frame = getattr(screen, '_last_drawn_frame', 0)
    print(f"Last drawn frame: {last_drawn_frame}")
    
    # Clear previous drawings
    if hasattr(screen, 'svg_scene'):
        screen.svg_scene.clear()
    
    # Add all new animations to this group
    for t in getattr(screen, '_turtles', []):
        new_elements = []
        svg = getattr(t, 'svg', None)
        if svg:
            for elem in svg.children:
                print(f"Processing element: {elem}")
                if elem.tagName in ['animate', 'animateMotion', 'animateTransform']:
                    elem_id = elem.getAttribute('Id')
                    if elem_id and 'animation_frame' in elem_id:
                        frame_num = int(elem_id.split('animation_frame')[1])
                        if frame_num > last_drawn_frame:
                            new_elements.append(elem)
        
        print(f"New elements for turtle: {len(new_elements)}")
        for elem in new_elements:
            # Adjust the timing to start immediately
            elem.setAttribute('begin', '0s')
            update_group <= elem.cloneNode(True)
    
    # Add new drawings to the group
    new_drawings = []
    if hasattr(screen, 'canvas'):
        for elem in screen.canvas.children:
            print(f"Processing canvas element: {elem}")
            elem_id = elem.getAttribute('Id')
            if elem_id and 'animation_frame' in elem_id:
                frame_num = int(elem_id.split('animation_frame')[1])
                if frame_num > last_drawn_frame:
                    new_drawings.append(elem)
            else:
                # If the element doesn't have an animation frame, add it anyway
                new_drawings.append(elem)
    
    print(f"New drawings: {len(new_drawings)}")
    for elem in new_drawings:
        elem.setAttribute('begin', '0s')
        update_group <= elem.cloneNode(True)
    
    # Add the update group to the scene
    if hasattr(screen, 'svg_scene'):
        screen.svg_scene <= update_group
    
    print("Updating display")
    # Update the display
    if not hasattr(turt, '_CFG'):
        print("Warning: turtle module has no _CFG attribute")
        turt._CFG = {}
    
    if "turtle_canvas_wrapper" not in turt._CFG or turt._CFG["turtle_canvas_wrapper"] is None:
        print("Creating new turtle canvas wrapper")
        turt._CFG["turtle_canvas_wrapper"] = turt.html.DIV(Id="turtle-canvas-wrapper")
        turt.document <= turt._CFG["turtle_canvas_wrapper"]
    
    if "turtle_canvas_id" not in turt._CFG or turt._CFG["turtle_canvas_id"] not in turt.document:
        print("Adding svg_scene to canvas wrapper")
        turt._CFG["turtle_canvas_wrapper"] <= screen.svg_scene
    
    def set_svg():
        # Force a redraw
        turt._CFG["turtle_canvas_wrapper"].html = turt._CFG["turtle_canvas_wrapper"].html
    
    turt.timer.set_timeout(set_svg, 1)
    
    # Update the last drawn frame
    screen._last_drawn_frame = current_frame
    print(f"Updated last_drawn_frame to {current_frame}")

# Add update function to turtle module
turtle.update = update

# Initialize _last_drawn_frame for Screen class if it doesn't exist
if not hasattr(turtle.Screen, '_last_drawn_frame'):
    turtle.Screen._last_drawn_frame = 0

# Add a reset function to clear the canvas and reset the frame counter
def reset_turtle():
    screen = turtle.Screen()
    if hasattr(screen, 'svg_scene'):
        screen.svg_scene.clear()
    screen._last_drawn_frame = 0
    screen.frame_index = 0
    turtle.restart()
    turtle_canvas = document['turtle-div-pane']
    turtle_canvas.clear()
    turtle.set_defaults(
        turtle_canvas_wrapper=turtle_canvas,
        width=turtle_canvas.clientWidth,
        height=turtle_canvas.clientHeight
    )

# Add the reset function to the turtle module
turtle.reset_turtle = reset_turtle


def print_output(*args, **kwargs):
    output = ' '.join(str(arg) for arg in args)
    end = kwargs.get('end', '\\n')
    window.update_output(output + end)

async def input_func(prompt=''):
    print_output(prompt, end='')
    future = aio.Future()
    def callback(value):
        future.set_result(value)
    window.set_input_callback(callback)
    return await future

sys.stdout.write = print_output
sys.stderr.write = print_output
__builtins__.input = input_func

# Set up turtle
turtle.restart()
turtle_canvas = document['turtle-div-pane']
turtle_canvas.clear()
turtle.set_defaults(
    turtle_canvas_wrapper=turtle_canvas,
    width=turtle_canvas.clientWidth,
    height=turtle_canvas.clientHeight
)

async def run_user_code():
    try:
${code}
    except Exception as e:
        print_output(f"Python Error: {type(e).__name__}: {str(e)}")

aio.run(run_user_code())
    `;
  
    // Append the script to the document body
    document.body.appendChild(scriptElement);

    // Set up the input callback function
    (window as any).set_input_callback = (callback: (value: string) => void) => {
      setIsWaitingForInput(true);
      (window as any).resolve_input = callback;
    };
  
    // Run the Python code using Brython
    try {
      (window as any).brython();
      
    } catch (error) {
      setOutput(prev => prev + 'Brython Error: ' + (error as Error).message + '\n');
      setIsRunning(false);
    }
  
    // Remove the script element
    document.body.removeChild(scriptElement);
  };
  
  // Add this function to the window object
  (window as any).update_output = (text: string) => {
    setOutput(prev => {
      const trimmed = (prev + text).replace(/\n+$/, '\n');
      return trimmed;
    });
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  };

  // Handle input submission
  const handleInputSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsWaitingForInput(false);
    setOutput(prev => prev + inputValue + '\n');
    if ((window as any).resolve_input) {
      (window as any).resolve_input(inputValue);
      (window as any).resolve_input = null;
    }
    setInputValue('');
  };

  */

  /*
  // code for running the brython turtle canvas
  <Tab.Content className="output-content">
                  <Tab.Pane eventKey="console">
                    {outputDestination === 'pane' && (
                      <div className="visual-output-container scrolling-wrapper custom-scrollbar">
                        <div id="p5-canvas-container"></div>
                        <div id="turtle-div-pane" className="turtle-canvas"></div>
                        <canvas id="turtle-canvas" width="800" height="600"></canvas>
                        <div id="pygame-sk-container-pane">
                          <div id="pygame-sk-canvas-pane"></div>
                        </div>
                        <div className="output-text-container scrolling-wrapper custom-scrollbar">
                          <pre ref={outputRef} className="output">
                            {output}
                            {isWaitingForInput && (
                              <form onSubmit={handleInputSubmit} style={{ display: 'inline' }}>
                                <input
                                  type="text"
                                  value={inputValue}
                                  onChange={(e) => setInputValue(e.target.value)}
                                  style={{ 
                                    background: 'transparent', 
                                    border: 'none', 
                                    outline: 'none',
                                    color: 'inherit',
                                    font: 'inherit',
                                    width: 'auto'
                                  }}
                                  autoFocus
                                />
                              </form>
                            )}
                          </pre>
                        </div>
                      </div>
                    )}

  */

  return (
    <div>
      <div className='row'>
        <div className='col-6'>
          <CodeMirrorEditor
            value={code}
            onUpdate={(code: string, caretPosition: { line: number; col: number }, highlightedRange: { anchor: { line: number; col: number }; head: { line: number; col: number } }) => {updateFiles(code)}}
            userInfo={userInfo} typingNames={[]} readOnly={false} currentUser={name} fileOwner={'student-' + name} isStudentFile={false} fileName={'main.py'} fileExtension={'py'} highlightOption={'code'} isOutputPane={false}
          />
        </div>
        <div className='col-6'>
          <div className="output-section">
            <div className="code-editor-footer files-pane-font">
              <button className="btn btn-success run-code-button" onClick={() => runCode()}>
                <i className="bi bi-play-fill mr-2"></i>
                Run
              </button>
            </div>
            <div className="visual-output-container scrolling-wrapper custom-scrollbar">
              <div id="turtle-canvas-pane" className="turtle-canvas"></div>
              <div className="output-text-container scrolling-wrapper custom-scrollbar">
                <pre className="output" style={{ color: 'white' }}>
                  {output}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestEditor;
