import React, { Suspense } from 'react';
import { Tab } from 'react-bootstrap';
import CodeMirrorEditor from './CodeMirrorEditor';
import ContentViewer from './code-editor/ContentViewer';
import P5SketchIFrame from './code-editor/P5SketchIFrame';

import { CodeData } from './types';
import { logger } from './Logger';

const QuillEditor = React.lazy(() => import('./QuillEditor'));

interface TabContentProps {
  codeDataRef: React.MutableRefObject<CodeData>;
  tabs: string[];
  isTeacher: boolean;
  paneType: 'codePane' | 'outputPane';
  lastTypingTimestamps: { [key: string]: { [key: string]: number } };
  name: string;
  permissionedFiles: string[];
  onUpdateFiles: (fileName: string, text: string, fileType: 'student' | 'teacher', isCodeFile: boolean, caretPosition?: { line: number; col: number }, highlightedRange?: { anchor: { line: number; col: number }; head: { line: number; col: number } }, studentName?: string) => void;
  getNamesTyping: (fileName: string) => string[];
  highlightOption: string;
  isRunningP5: boolean;
  p5SketchCode: string;
  runKey: number;
  output: string;
  isFullScreenSlides: boolean;
  isFullScreenQuiz: boolean;
  toggleFullScreenSlides: () => void;
  toggleFullScreenQuiz: () => void;
  handleContentUrlChange: (fileName: string, newUrl: string) => void;
}

const TabContent: React.FC<TabContentProps> = ({
  codeDataRef,
  tabs,
  isTeacher,
  paneType,
  lastTypingTimestamps,
  name,
  permissionedFiles,
  onUpdateFiles,
  getNamesTyping,
  highlightOption,
  isRunningP5,
  p5SketchCode,
  runKey,
  output,
  isFullScreenSlides,
  isFullScreenQuiz,
  toggleFullScreenSlides,
  toggleFullScreenQuiz,
  handleContentUrlChange,
}) => {
  return (
    <div className={`tab-content ${paneType === 'outputPane' ? 'output' : 'main'}`}>
      {tabs.map((tabName) => {
        const [fileType, ...rest] = tabName.split('-');
        const fileName = rest.pop() ?? '';
        const studentName = rest.join('-');
        const fileExtension = fileName.split('.').pop()?.toLowerCase();

        return (
          <Tab.Pane key={tabName} eventKey={tabName}>
            {fileExtension === 'txt' ? (
              <div className="quill-container">
                <Suspense fallback={<div>Loading editor...</div>}>
                  <QuillEditor
                    value={codeDataRef.current.teacherFiles?.[fileName]?.text || ''}
                    onChange={(text: string) => {
                      if (isTeacher) {
                        onUpdateFiles(fileName, text, 'teacher', false);
                      }
                    }}
                    readOnly={!isTeacher}
                  />
                </Suspense>
              </div>
            ) : fileExtension === 'slides' ? (
              <ContentViewer 
                contentType='slides'
                url={codeDataRef.current.teacherFiles?.[fileName]?.text || ''}
                isFullScreen={isFullScreenSlides}
                onFullScreenToggle={toggleFullScreenSlides}
                isTeacher={isTeacher}
                onUrlChange={(newUrl: any) => handleContentUrlChange(fileName, newUrl)}
              />
            ) : fileExtension === 'quiz' ? (
              <ContentViewer
                contentType='quiz'
                url={codeDataRef.current.teacherFiles?.[fileName]?.text || ''}
                isFullScreen={isFullScreenQuiz}
                onFullScreenToggle={toggleFullScreenQuiz}
                isTeacher={isTeacher}
                onUrlChange={(newUrl: any) => handleContentUrlChange(fileName, newUrl)}
              />
            ) : (
              <CodeMirrorEditor
                value={
                  fileType === 'teacher'
                    ? codeDataRef.current.teacherFiles?.[fileName]?.text || ''
                    : codeDataRef.current.studentFiles?.[studentName || name]?.[fileName]?.text || ''
                }
                onUpdate={(text, caretPosition, highlightedRange) => 
                  onUpdateFiles(fileName, text, fileType as 'student' | 'teacher', true, caretPosition, highlightedRange, studentName || name)}
                readOnly={fileType === 'teacher' && !isTeacher}
                userInfo={codeDataRef.current.userInfo}
                typingNames={getNamesTyping(fileName)}
                currentUser={name}
                fileOwner={fileType === 'teacher' ? 'teacher-name' : `student-${studentName || name}`}
                isStudentFile={fileType !== 'teacher'}
                fileName={fileName}
                fileExtension={fileExtension ?? 'py'}
                highlightOption={highlightOption}
                isOutputPane={paneType === 'outputPane'}
              />
            )}
          </Tab.Pane>
        );
      })}
      {paneType === 'outputPane' && (
        <Tab.Pane eventKey="console">
          {isRunningP5 && (<P5SketchIFrame code={p5SketchCode} runKey={runKey} />)}
          <div className="visual-output-container">
            <div id="turtle-canvas-pane" className="turtle-canvas"></div>
            <div id="pygame-sk-container-pane">
              <div id="pygame-sk-canvas-pane"></div>
            </div>
            <div className="output-text-container custom-scrollbar">
              <pre className="output" id="output-direct">{output}</pre>
            </div>
            <div className="input-container-container"></div>
          </div>
        </Tab.Pane>
      )}
    </div>
  );
};

export default TabContent;