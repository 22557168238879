import React, { useState } from 'react';

import { FaSun, FaMoon, FaPalette } from 'react-icons/fa';
import { Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';

import { useColorScheme } from './ColorSchemeContext';
import { colorSchemes } from './ColorSchemes';

import './ColorSchemeSelector.css';

interface ColorSchemeSelectorProps {
  modalRef: React.RefObject<HTMLDivElement>;
}

const ColorSchemeSelector: React.FC<ColorSchemeSelectorProps> = ({ modalRef }) => {
  const { currentScheme, changeColorScheme, setCustomColorScheme } = useColorScheme();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const otherSchemes = Object.entries(colorSchemes).filter(([key]) => !['light', 'dark'].includes(key));

  const handleSchemeChange = (schemeName: string) => {
    changeColorScheme(schemeName);
  };

  return (
    <>
      <div className="color-scheme-selector d-flex align-items-center">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="light-mode-tooltip">Light Mode</Tooltip>}
        >
          <Button 
            variant="light" 
            className={`scheme-button rounded-circle ${currentScheme === colorSchemes.light ? 'active' : ''}`}
            onClick={() => changeColorScheme('light')}
            style={{
              backgroundColor: colorSchemes.light.background,
              color: colorSchemes.light.foreground,
              borderColor: colorSchemes.light.foreground
            }}
          >
            <FaSun />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="light-mode-tooltip">Dark Mode</Tooltip>}
        >
          <Button 
            variant="dark" 
            className={`scheme-button rounded-circle ${currentScheme === colorSchemes.dark ? 'active' : ''}`}
            onClick={() => changeColorScheme('dark')}
            style={{
              backgroundColor: colorSchemes.dark.background,
              color: colorSchemes.dark.foreground,
              borderColor: colorSchemes.dark.foreground
            }}
          >
            <FaMoon />
          </Button>
        </OverlayTrigger>
      </div>

      <div className="header-dropdown-item colors" onClick={handleShow}>
        <FaPalette /> Colors
      </div>
      {/* <Button onClick={handleShow} className="mt-2">
        <FaPalette /> Colors
      </Button> */}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Color Schemes</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modalRef} onClick={(e) => e.stopPropagation()}> {/* Prevent clicks from closing the modal */}
          <div className="light-dark-selector">
            <span className="mode-label">Light Mode</span>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="light-mode-tooltip">Light Mode</Tooltip>}
            >
              <Button 
                variant="light" 
                className={`scheme-button rounded-circle me-2 ${currentScheme === colorSchemes.light ? 'active' : ''}`}
                onClick={() => handleSchemeChange('light')}
                style={{
                  backgroundColor: colorSchemes.light.background,
                  color: colorSchemes.light.foreground,
                  borderColor: colorSchemes.light.foreground
                }}
              >
                <FaSun />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="dark-mode-tooltip">Dark Mode</Tooltip>}
            >
              <Button 
                variant="dark" 
                className={`scheme-button rounded-circle ${currentScheme === colorSchemes.dark ? 'active' : ''}`}
                onClick={() => handleSchemeChange('dark')}
                style={{
                  backgroundColor: colorSchemes.dark.background,
                  color: colorSchemes.dark.foreground,
                  borderColor: colorSchemes.dark.foreground
                }}
              >
                <FaMoon />
              </Button>
            </OverlayTrigger>
            <span className="mode-label">Dark Mode</span>
          </div>

          <div className="other-schemes-container">
            {otherSchemes.map(([name, scheme]) => (
              <Button 
                key={name}
                className={`scheme-button ${currentScheme === scheme ? 'active' : ''}`}
                onClick={() => handleSchemeChange(name)}
                style={{
                  backgroundColor: scheme.background,
                  color: scheme.foreground,
                  borderColor: scheme.foreground,
                }}
              >
                {name.charAt(0).toUpperCase() + name.slice(1)}
              </Button>
            ))}
          </div>
          <div className="custom-color-selector">Select custom colours</div>
          
          <div className="custom-color-selector">
            <span className="mode-label">Background</span>
            <input 
              type="color" 
              value={currentScheme.background}
              onChange={(e) => setCustomColorScheme(e.target.value, currentScheme.foreground, currentScheme.mainPurple)} 
              title="Background Color"
              style={{ borderColor: currentScheme.foreground }}
            />
            <input 
              type="color" 
              value={currentScheme.foreground}
              onChange={(e) => setCustomColorScheme(currentScheme.background, e.target.value, currentScheme.mainPurple)} 
              title="Foreground Color"
              style={{ borderColor: currentScheme.background }}
            />
            <span className="mode-label">Foreground</span>
            <input 
              type="color" 
              value={currentScheme.mainPurple}
              onChange={(e) => setCustomColorScheme(currentScheme.background, currentScheme.foreground, e.target.value)} 
              title="Student Background Color"
              style={{ borderColor: currentScheme.foreground }}
            />
            <span className="mode-label">Student Color</span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ColorSchemeSelector;