import React, { useEffect, useState } from 'react';
import { FaChevronUp, FaChevronDown, FaPlus, FaAlignJustify, FaLock, FaFolder } from 'react-icons/fa';
import { FaPeopleGroup } from 'react-icons/fa6';
import { RxEyeOpen, RxEyeClosed } from 'react-icons/rx';
import { BsBroadcast, BsPerson, BsQuestionSquare } from 'react-icons/bs';


import FileUserIndicator from './FileUserIndicator';
import FolderUserIndicator from './FolderUserIndicator';
import TimerComponent from './code-editor/TimerComponent';
import LessonDropdown from './code-editor/LessonDropdown';
import FileRenameInput from './code-editor/FileRenameInput';
import FileDropdown from './code-editor/FileDropdown';
import FileIcon from './code-editor/FileIcon';

import { getLessonString, getInitials } from './utilities';
import { CodeData, LessonInfo } from './types';
import { logger } from './Logger';

import './CodeEditor.css';
import './FilesPane.css';


// To note, maybe this should be split into a lesson component and a files component they are disjoint, changing between them would cause a rerender either way
// have a think about this, maybe rerender is quicker than a whole remount



interface FilesPaneProps {
  codeDataRef: React.MutableRefObject<CodeData>;
  isTeacher: boolean;
  name: string;
  course: string;
  lesson: string;
  permissionedFiles: string[];
  structureVersion: number;
  lastTeacherTextUpdateTimestamp: number | null,

  onSwitchLesson: (lesson: string) => void;
  onCreateLesson: () => void;
  onUpdateLessonName: (lessonNameOld: string, lessonNameNew: string) => void;
  onUpdateLessonState: (lessonInfo: LessonInfo) => void;
  onUpdateFileHidden: (fileName: string, hidden: boolean) => void;
  onDeleteLesson: (lessonName: string) => void;
  
  onCreateFile: (fileType: 'student' | 'teacher', studentName?: string) => void;
  onUpdateFileName: (oldFileName: string, newFileName: string, fileType: 'student' | 'teacher', studentName?: string) => void;
  onUpdateFilePermission: (fileName: string, permission: boolean) => void;
  onUpdateFileClassOnly: (fileName: string, isClassOnly: boolean) => void;
  onDeleteFile: (fileName: string, fileType: 'student' | 'teacher', studentName?: string) => void;
  
  onAddTab: (fileName: string, fileType: 'student' | 'teacher', studentName?: string) => void;
  onSetCurrentFile: (fileName: string) => void;
}

const FilesPane: React.FC<FilesPaneProps> = React.memo(({
  codeDataRef,
  isTeacher,
  name,
  course,
  lesson,
  permissionedFiles,
  structureVersion,
  lastTeacherTextUpdateTimestamp,

  onSwitchLesson,
  onCreateLesson,
  onUpdateLessonName,
  onUpdateLessonState,
  onDeleteLesson,
  
  onCreateFile,
  onUpdateFileHidden,
  onUpdateFileName,
  onUpdateFilePermission,
  onUpdateFileClassOnly,
  onDeleteFile,

  onAddTab,
  onSetCurrentFile,
}) => {
  logger.log('Creating FilesPane');
  const [showLessonsDropdown, setShowLessonsDropdown] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({ lesson: false, myFiles: false, studentFiles: false, peopleHere: true });
  const [renameLessonName, setRenameLessonName] = useState('');
  const [renameFileName, setRenameFileName] = useState('');
  const [expandedStudents, setExpandedStudents] = useState<string[]>([]);
  const secondsToShowBroadcast = 60;
  const [isTemplate, setIsTemplate] = useState<boolean>(false);

  // feature flags
 
  const showFFFileUserIndicator: boolean = false;  // feature flag for file user indicators
  const showFFActivityTimer: boolean = false;  // feature flag for activity timer
  const showFFLiveCodingOnStudentFiles: boolean = false;  // feature flag for live coding on student files


  useEffect(() => {
    const checkTemplateState = () => {
      const { lessonsInfo } = codeDataRef.current;
      
      if (lessonsInfo.length === 0) {
        setIsTemplate(false);
        return;
      }

      const templateLessons = lessonsInfo.filter(lesson => lesson.state === 'template');

      if (templateLessons.length === lessonsInfo.length) {
        setIsTemplate(true);
      } else if (templateLessons.length > 0) {
        setIsTemplate(true);
        logger.warn('Some lessons are templates, but not all. This should never happen.');
      } else {
        setIsTemplate(false);
      }
    };

    checkTemplateState();
  }, [codeDataRef.current.lessonsInfo]);

  

  const handleLessonIconClick = () => setShowLessonsDropdown(!showLessonsDropdown);

  const toggleSection = (section: string) => {
    setCollapsedSections((prevState: any) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const toggleStudentFiles = (studentName: string) => {
    setExpandedStudents((prevExpandedStudents) => {
      if (prevExpandedStudents.includes(studentName)) {
        return prevExpandedStudents.filter((name) => name !== studentName);
      } else {
        return [...prevExpandedStudents, studentName];
      }
    });
  };

   // some duplicate code, tidy this up
   const shouldShowBroadcastIcon = (fileName: string) => {
    // logger.log('FP: SSBI, fileName: ', fileName, ', lastTeacherTextUpdateTimestamp: ', lastTeacherTextUpdateTimestamp, ', codeDataRef.current.teacherTextEditFile: ', codeDataRef.current.teacherTextEditFile);
    const fullFileName = 'teacher-name-' + fileName;
    if (!lastTeacherTextUpdateTimestamp || !codeDataRef.current.teacherTextEditFile) return false;
    const elapsedSeconds = (Date.now() - lastTeacherTextUpdateTimestamp) / 1000;
    return elapsedSeconds <= secondsToShowBroadcast && fullFileName === codeDataRef.current.teacherTextEditFile;
  };
  
  const shouldShowBroadcastIconPeopleHere = (teacherName: string) => {
    // logger.log('FP: SSBIPH, teacherName: ', teacherName, ', lastTeacherTextUpdateTimestamp: ', lastTeacherTextUpdateTimestamp, ', codeDataRef.current.teacherTextEditFile: ', codeDataRef.current.teacherTextEditFile);
    if (!lastTeacherTextUpdateTimestamp || !codeDataRef.current.teacherTextEditFile) return false;
    const studentName = `student-${name}`;
    if (!(codeDataRef.current.teacherTextEditFile.startsWith('teacher-name-') || 
    (codeDataRef.current.teacherTextEditFile.startsWith(studentName) && showFFLiveCodingOnStudentFiles))) return false;
    if (teacherName !== codeDataRef.current.teacherTextEditName) return false;
    const elapsedSeconds = (Date.now() - lastTeacherTextUpdateTimestamp) / 1000;
    return elapsedSeconds <= secondsToShowBroadcast;
  };

  const shouldShowBroadcastIconStudent = (fileName: string) => {
    // logger.log('CodeEditor: SSBIS, fileName: ', fileName, ', lastTeacherTextUpdateTimestamp: ', lastTeacherTextUpdateTimestamp, ', codeDataRef.current.teacherTextEditFile: ', codeDataRef.current.teacherTextEditFile);
    if (!showFFLiveCodingOnStudentFiles) return false;
    const fullFileName = 'student-' + name + '-' + fileName;
    if (!lastTeacherTextUpdateTimestamp || !codeDataRef.current.teacherTextEditFile) return false;
    const elapsedSeconds = (Date.now() - lastTeacherTextUpdateTimestamp) / 1000;
    return elapsedSeconds <= secondsToShowBroadcast && fullFileName === codeDataRef.current.teacherTextEditFile;
  };

  
  const completeRenaming = (fileType: 'student' | 'teacher', newFileName: string, studentName?: string) => {
    logger.log('completeRenaming: fileType: ', fileType, ', renameFileName: ', renameFileName, ', newFileName: ', newFileName, ', studentName: ', studentName);
    onUpdateFileName(renameFileName, newFileName, fileType, studentName);
    setRenameFileName('');
  };


  


  return (
    <div className="files-pane-container">
      <div className="files-pane files-pane-font">
        <div className="files-list-top-container tab-section">
          <span className="file-name-text">{course}</span>
          <FaAlignJustify className={`lesson-dropdown-icon ${showLessonsDropdown ? 'selected' : ''}`} onClick={handleLessonIconClick} />
        </div>
        {showLessonsDropdown ? (
          <div className="lessons-dropdown">
            {codeDataRef.current.lessonsInfo
              .filter(lessonTemp => (isTeacher || lessonTemp.state !== 'hidden'))
              .map((lessonTemp) => {
                const isSelected = lessonTemp.name === lesson;
                return (
                  <div 
                    key={lessonTemp.id} 
                    className={`lesson ${lessonTemp.state} ${isTeacher ? 'teacher' : 'student'} ${isSelected ? 'selected' : ''}`} 
                    onClick={() => {
                      if ((isTeacher || lessonTemp.state !== 'locked') && !isSelected) {
                        onSwitchLesson(lessonTemp.name);
                        setShowLessonsDropdown(false);
                      }
                    }} 
                  >
                    <div className='lesson-content'>
                      <div className={`lesson-icon ${lessonTemp.state} ${isTeacher ? 'teacher' : 'student'} ${isSelected ? 'selected' : ''}`}>
                        <i className={`bi ${isSelected ? 'bi-file-fill selected' : 'bi-files'} lesson-icon`}></i>
                        <span className={`lesson-number ${lessonTemp.state} ${isTeacher ? 'teacher' : 'student'} ${isSelected ? 'selected' : ''}`}>{lessonTemp.id}</span>
                      </div>
                      {renameLessonName !== lessonTemp.name && (
                        <div className={`lesson-name ${lessonTemp.state} ${isTeacher ? 'teacher' : 'student'} ${isSelected ? 'selected' : ''}`}>
                          {getLessonString(codeDataRef.current.lessonsInfo, lessonTemp.name)}
                        </div>
                      )}
                    </div>
                    <div className='flex-div'>
                      {lessonTemp.state === 'locked' && renameLessonName !== lessonTemp.name && (
                        <FaLock className='lock-icon'/>
                      )}
                      {isTeacher && !isTemplate && (
                        renameLessonName === lessonTemp.name ? (
                          <FileRenameInput
                            initialFileName={lessonTemp.name}
                            onRenameComplete={(newLessonName) => {
                              onUpdateLessonName(lessonTemp.name, newLessonName);
                              setRenameLessonName('');
                            }}
                            onCancel={() => setRenameLessonName('')}
                          />
                        ) : (
                          <LessonDropdown
                            lesson={lessonTemp}
                            onEditName={() => setRenameLessonName(lessonTemp.name)}
                            onDelete={onDeleteLesson}
                            onStateChange={onUpdateLessonState}
                          />
                        )
                      )}
                    </div>
                  </div>
                );
              })
            }
            {isTeacher && !isTemplate && (
              <div className="add-lesson" onClick={onCreateLesson}>
                <FaPlus className="add-lesson-icon" />
                <div className="add-lesson-text">Add Lesson</div>
              </div>
            )}
          </div>
        ) : (
          <div className="files-list-container-container">
            <div className="files-list-container margin-bottom-1">
              <div className={`file-name-container-title file-name-title ${collapsedSections.lesson ? '' : 'file-name-margin'}`} id="lesson">
                {collapsedSections.lesson ? (
                  <FaChevronUp className="chevron-icon" onClick={() => toggleSection('lesson')} />
                ) : (
                  <FaChevronDown className="chevron-icon" onClick={() => toggleSection('lesson')} />
                )}
                <span className="lesson-name">
                  {getLessonString(codeDataRef.current.lessonsInfo, lesson)}
                </span>
                {isTeacher && !isTemplate && <FaPlus className="add-file-icon" onClick={() => {onCreateFile('teacher');}} />}
              </div>
              {!collapsedSections.lesson && (<div className="files-section-divider"></div>)}
              {!collapsedSections.lesson && (
                <div className="files-vertical-scroll custom-scrollbar">
                  {Object.keys(codeDataRef.current.teacherFiles ?? {})
                    .sort((a, b) => {
                      const extA = a.split('.').pop();
                      const extB = b.split('.').pop();
                      if (extA === 'py' && extB !== 'py') return 1;
                      if (extA !== 'py' && extB === 'py') return -1;
                      return 0;
                    })
                    .filter(fileName => (isTeacher || !codeDataRef.current.teacherFiles?.[fileName]?.hidden))
                    .map((fileName) => {
                      
                    const isPermissioned = permissionedFiles.includes(fileName);
                    const isClassOnly = codeDataRef.current.teacherFiles?.[fileName]?.classOnly ?? true;
                    return (
                      <div key={fileName} >
                        {(isTeacher && renameFileName === fileName) ? (
                          <FileRenameInput
                            initialFileName={fileName}
                            onRenameComplete={(newFileName) => {
                              completeRenaming('teacher', newFileName);
                            }}
                            onCancel={() => setRenameFileName('')}
                          />
                        ) : (
                          <div className="file-item-content" >
                            <div className="file-name-container">
                              <span className="file-name" style={{ opacity: codeDataRef.current.teacherFiles?.[fileName]?.hidden ? 0.7 : 1 }} onClick={() => {
                                onAddTab(fileName, 'teacher');
                              }}>
                                <FileIcon fileName={fileName} isTab={false} />
                                <span className={`file-name-text ${(isClassOnly && isTeacher) ? 'pink' : ''}`}>{fileName}</span>
                              </span>
                              <div className="flex-div">
                                {showFFFileUserIndicator && (
                                  <FileUserIndicator codeData={codeDataRef.current} fileName={fileName} />
                                )}
                                {shouldShowBroadcastIcon(fileName) && (
                                  <BsBroadcast className="broadcast-icon"/>
                                )}
                                {(isTeacher || isPermissioned) && (
                                  <div 
                                    className={`permission-icon ${isPermissioned ? 'permissioned' : 'not-permissioned'}`}
                                    onClick={() => isTeacher && onUpdateFilePermission(fileName, !isPermissioned)}
                                    style={{ cursor: isTeacher ? 'pointer' : 'default' }}
                                  >
                                    <FaPeopleGroup />
                                  </div>
                                )}
                                {isTeacher && (
                                  <div 
                                    className="eye-icon" 
                                    onClick={() => onUpdateFileHidden(fileName, !codeDataRef.current.teacherFiles?.[fileName]?.hidden)}
                                  >
                                    {codeDataRef.current.teacherFiles?.[fileName]?.hidden ? <RxEyeClosed /> : <RxEyeOpen />}
                                  </div>
                                )}
                                {isTeacher && !isTemplate && (
                                  <FileDropdown
                                    isHorizontalDots={true}
                                    fileName={fileName}
                                    allowModifyFileName={true}
                                    allowDeleteFile={true}
                                    isClassOnly={isClassOnly}
                                    allowChangeClassOnly={true}
                                    onRenameClick={(fileNameInternal) => {
                                      logger.log('editing fileName: ', fileNameInternal);
                                      setRenameFileName(fileNameInternal);
                                    }}
                                    onDeleteClick={(fileNameInternal) => onDeleteFile(fileNameInternal, 'teacher', name)}
                                    onChangeClassOnly={(fileNameInternal, isClassOnlyInternal) => onUpdateFileClassOnly(fileNameInternal, isClassOnlyInternal)}
                                  />  
                                )}
                              </div>    
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {!isTeacher && (
              <div className="files-list-container dark-purple margin-bottom-1">
                <div className={`file-name-container-title file-name-title ${collapsedSections.myFiles ? '' : 'file-name-margin'}`} id="myFiles">
                  {collapsedSections.myFiles ? (
                    <FaChevronUp className="chevron-icon" onClick={() => toggleSection('myFiles')} />
                  ) : (
                    <FaChevronDown className="chevron-icon" onClick={() => toggleSection('myFiles')} />
                  )}
                  <span className='lesson-name'>
                    My Project
                  </span>
                  <FaPlus className="add-file-icon" onClick={() => onCreateFile('student', name)} />
                </div>
                {!collapsedSections.myFiles && (<div className="files-section-divider-student"></div>)}
                {!collapsedSections.myFiles && (
                  <div className="files-vertical-scroll custom-scrollbar" key={structureVersion}>
                    {Object.keys(codeDataRef.current.studentFiles?.[name] ?? {}).map((fileName) => (
                      <div key={fileName} >
                        {renameFileName === fileName ? (
                          <FileRenameInput
                            initialFileName={fileName}
                            onRenameComplete={(newFileName) => {
                              logger.log('callback onRenameComplete, newFileName: ', newFileName);
                              completeRenaming('student', newFileName, name);
                            }}
                            onCancel={() => setRenameFileName('')}
                          />
                        ) : (
                          <div className="file-item-content">
                            <div className="file-name-container">
                              <span className="file-name" onClick={(e) => {
                                e.stopPropagation();
                                logger.log('clicked on fileName: ', fileName);
                                onAddTab(fileName, 'student');
                              }}>
                                <FileIcon fileName={fileName} isTab={false} />
                                <span className="file-name-text">{fileName}</span>
                              </span>
                              <div className="file-right-icons">
                                {showFFFileUserIndicator && (
                                  <FileUserIndicator codeData={codeDataRef.current} fileName={fileName} studentName={name} />
                                )}
                                {shouldShowBroadcastIconStudent(fileName) && (
                                  <BsBroadcast className="broadcast-icon margin-right-small"/>
                                )}
                                <FileDropdown
                                  isHorizontalDots={true}
                                  fileName={fileName}
                                  allowModifyFileName={true}
                                  allowDeleteFile={true}
                                  isClassOnly={true}
                                  allowChangeClassOnly={false}
                                  onRenameClick={(fileName) => {
                                    logger.log('editing fileName: ', fileName);
                                    setRenameFileName(fileName);
                                  }}
                                  onDeleteClick={(fileName) => onDeleteFile(fileName, 'student', name)}
                                  onChangeClassOnly={() => {}}
                                />   
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {isTeacher && (
              <div className="files-list-container dark-purple margin-bottom-1">
                <div className={`file-name-title ${collapsedSections.studentFiles ? '' : 'file-name-margin'}`} id="studentFiles">
                  {collapsedSections.studentFiles ? (
                    <FaChevronUp className="chevron-icon" onClick={() => toggleSection('studentFiles')} />
                  ) : (
                    <FaChevronDown className="chevron-icon" onClick={() => toggleSection('studentFiles')} />
                  )}
                  <span className='lesson-name'>
                    Student files
                  </span>
                </div>
                {!collapsedSections.studentFiles && (<div className="files-section-divider-student"></div>)}
                {!collapsedSections.studentFiles && (
                  <div className="files-vertical-scroll custom-scrollbar">
                    {Object.keys(codeDataRef.current.studentFiles ?? {}).map((studentName) => {
                      const isConnected = codeDataRef.current.studentConnections?.includes(studentName);

                      return (
                        <div key={studentName} className={`student-folder mb-2 ${isConnected ? '' : 'student-disconnected'}`}>
                          <div className="student-folder-header mb-2">
                            <div className="student-folder-title" onClick={() => toggleStudentFiles(studentName)}>
                              <FaFolder className="folder-icon" />
                              <span className="student-name lesson-name">
                                {studentName}
                                {showFFActivityTimer && codeDataRef.current.lastEditTimes?.[studentName] && (
                                  <TimerComponent
                                    lastEditTime={codeDataRef.current.lastEditTimes[studentName]}
                                    currentTimestampFE={codeDataRef.current.currentTimestampFE}
                                    currentTimestampBE={codeDataRef.current.currentTimestampBE}
                                  />
                                )}
                              </span>
                            </div>
                            <div className="student-folder-actions">
                              <FaPlus
                                className="add-file-icon add-file-icon-rm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onCreateFile('student', studentName);
                                }}
                              />
                              {expandedStudents.includes(studentName) ? (
                                <FaChevronDown className="collapse-icon collapse-margin-right" onClick={() => toggleStudentFiles(studentName)} />
                              ) : (
                                <FaChevronUp className="collapse-icon collapse-margin-right" onClick={() => toggleStudentFiles(studentName)} />
                              )}
                            </div>
                          </div>
                          {expandedStudents.includes(studentName) && (
                            <div className="student-files mb-2">
                              {codeDataRef.current.studentFiles && Object.keys(codeDataRef.current.studentFiles[studentName] ?? {}).map((fileName) => (
                                <div key={fileName} className="file-item-content mb-2">
                                  <div className="file-name-container">
                                    <span className="file-name" onClick={() => {
                                      onAddTab(fileName, 'student', studentName);
                                      onSetCurrentFile(`student-${studentName}-${fileName}`);
                                    }}>
                                      <FileIcon fileName={fileName} isTab={false} />
                                      <span className="file-name-text">{fileName}</span>
                                    </span>
                                    {showFFFileUserIndicator && (
                                      <FileUserIndicator codeData={codeDataRef.current} fileName={fileName} studentName={studentName} />
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="files-list-container-empty"></div>
            <div className="files-list-container">
              <div className={`file-name-container-title file-name-title ${collapsedSections.peopleHere ? '' : 'file-name-margin'} d-flex justify-content-between align-items-center`} id="peopleHere">
                {collapsedSections.peopleHere ? (
                  <FaChevronUp className="chevron-icon" onClick={() => toggleSection('peopleHere')} />
                ) : (
                  <FaChevronDown className="chevron-icon" onClick={() => toggleSection('peopleHere')} />
                )}
                <span className="lesson-name">People here</span>
                <span className="people-count">
                  {(codeDataRef.current.teacherConnections?.length || 0) + (codeDataRef.current.studentConnections?.length || 0)}
                </span>
              </div>
              {!collapsedSections.peopleHere && (<div className="files-section-divider"></div>)}
              {!collapsedSections.peopleHere && (
                <div className="files-vertical-scroll custom-scrollbar">
                  {codeDataRef.current.teacherConnections && codeDataRef.current.teacherConnections.map((teacherName) => (
                    <div key={teacherName} className="student-item mb-2">
                      <div className="people-here-left-part">
                        <span className="people-here-initials-circle teacher">{getInitials(teacherName)}</span>
                        <span className="people-here-name lesson-name">{teacherName}</span>
                      </div>
                      <div className="people-here-right-icon">
                        {shouldShowBroadcastIconPeopleHere(teacherName) && (
                          <BsBroadcast className="broadcast-icon"/>
                        )}
                      </div>
                    </div>
                  ))}
                  {codeDataRef.current.studentConnections && codeDataRef.current.studentConnections.map((studentName) => (
                    <div key={studentName} className="student-item mb-2">
                      <div className="people-here-initials-circle student">{getInitials(studentName)}</div>
                      <span className="student-name lesson-name">{studentName}</span>
                    </div>
                  ))}
                  {codeDataRef.current.allStudents.filter(
                    (studentName) => !codeDataRef.current.studentConnections?.includes(studentName)
                  ).map((disconnectedStudent) => (
                    <div key={disconnectedStudent} className="student-item mb-2 disconnected-student">
                      <div className="people-here-left-part">
                        <span className="people-here-initials-circle student" style={{ opacity: 0.7 }}>
                          {getInitials(disconnectedStudent)}
                        </span>
                        <span className="people-here-name lesson-name" style={{ opacity: 0.7 }}>
                          {disconnectedStudent}
                        </span>
                      </div>
                      <div className="people-here-right-icon">
                        <BsPerson className="empty-person-icon" />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default FilesPane;